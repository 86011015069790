import React from "react";

/* Sections */
import BestSellerSection from "./Sections/BestSeller/BestSellerSection";
import TipsSection from "./Sections/TipsSection";
import ShoppingInfo from "./Sections/ShoppingInfo";
import ProductSections from "./Sections/ProductSections.js";

import Box from "@mui/material/Box";

import Banner from "views/Banner/Banner";

function HomePage() {
  return (
    <div>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Banner></Banner>
      </Box>
      <ProductSections />
      <BestSellerSection />
      <TipsSection />
      <ShoppingInfo />
    </div>
  );
}

export default HomePage;
