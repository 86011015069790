const title = {
  fontFamily: "Nunito Sans, sans-serif",
};

const text = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "300",
};

const container = {
  padding: "5%",
  textAlign: "start",
};

export default function PoliticasDeCompra() {
  return (
    <div style={container}>
      <h2 style={title}> POLÍTICAS DE COMPRA </h2>

      <h3 style={title}> Tiempos de Entrega </h3>
      <p style={text}>
        El tiempo de entrega estándar de nuestros productos es de 2 a 5 días
        hábiles, excepto en áreas extendidas donde el tiempo de entrega será de
        5 a 10 días hábiles. Para una estimación más precisa, puede contactarnos
        proporcionando su código postal y le responderemos a la brevedad.
      </p>

      <h3 style={title}>Envío Urgente</h3>
      <p style={text}>
        Para envíos EXPRESS con un tiempo de entrega de 1 a 2 días hábiles,
        deberá comunicarse con nosotros vía WhatsApp antes de realizar la compra
        para confirmar la disponibilidad y el costo adicional del servicio.
      </p>

      <h3 style={title}>Seguimiento del Paquete</h3>
      <p style={text}>
        Después de realizar su compra, le proporcionaremos el número de guía y
        la paquetería correspondiente vía WhatsApp. Podrá realizar el
        seguimiento en el sitio web de la paquetería o consultarnos directamente
        a través de WhatsApp.
      </p>

      <h3 style={title}>Costos de Envío</h3>
      <p style={text}>
        - Envío Gratis: Para compras iguales o superiores a $549.00 en envío
        estándar.
      </p>
      <p style={text}>
        - Envío Estándar: Para compras inferiores a $549.00, el costo del envío
        será de $149.00.
      </p>

      <h3 style={title}>Lugar de Origen de los Envíos</h3>
      <p style={text}>
        Nuestros productos se envían desde Celaya, Guanajuato, a toda la
        República Mexicana a través de diversas paqueterías como PAQUETEXPRESS,
        FEDEX y ESTAFETA. Los envíos se realizan el mismo día si están dentro
        del horario laboral de la paquetería; de lo contrario, se despacharán el
        día hábil siguiente.
      </p>

      <h3 style={title}>Dirección de Envío</h3>
      <p style={text}>
        El producto será enviado a la dirección proporcionada durante el proceso
        de pago. Si necesita cambiar la dirección, por favor, comuníquese con
        nosotros a través de WhatsApp, Facebook o Instagram.
      </p>

      <h3 style={title}>Pagos</h3>
      <p style={text}>
        Ofrecemos opciones seguras de pago con tarjeta de crédito, débito y
        PayPal a través de nuestra página web. También aceptamos transferencias
        electrónicas; para esto, contáctenos vía redes sociales para recibir los
        datos necesarios.
      </p>

      <h3 style={title}>Reembolsos</h3>
      <p style={text}>
        - PayPal: El reembolso puede tardar de 8 a 10 días hábiles en reflejarse
        en su cuenta.
      </p>
      <p style={text}>
        - Tarjeta de Crédito o Débito: El reembolso puede tardar aproximadamente
        25 días hábiles.
      </p>

      <h3 style={title}>Facturación</h3>
      <p style={text}>
        Los precios mostrados son netos. Puede solicitar una factura enviando
        sus datos fiscales al correo afinazone@outlook.com o a través de nuestro
        WhatsApp, adjuntando el número de su pedido. Le recomendamos
        solicitarnos la factura a la brevedad.
      </p>

      <p style={text}>
        Para cualquier duda o asistencia, estamos a su disposición en nuestros
        medios de contacto.
      </p>
    </div>
  );
}
