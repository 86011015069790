
import { storage } from "ConfigFirebase";
import { ref, listAll, getDownloadURL, getStorage, uploadBytes, deleteObject } from "firebase/storage";

export async function getImagesFromUpdateProduct(product, id, callback) {

  // Create a reference under which you want to list
  const listRef = ref(storage, `images/${product}/${id}`);

  var previewImages = [];
  var images = [];

  var count = 0;
  const addFile = () => {
    count++;
    if (count === list.items.length) {
      callback(images, previewImages);
    }
  };

  // Find all the prefixes and items.
  const list = await listAll(listRef);
  for (const i of list.items) {
    await getDownloadURL(i)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          previewImages.push(url);
          images.push(xhr.response);
          addFile();
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        alert('error al cargar imágenes, recargue la página y revise su conexión');
        callback([], []);
      });
  }
};

export async function getPreviewImageUrlFromProduct(id, product) {

  // Create a reference under which you want to list
  const imageRef = ref(storage, `images/${product}/${id}/0-image`);
  var response = null;
  await getDownloadURL(imageRef)
    .then((url) => response = url)
    .catch((error) => { return 'error'; });
  return response;
};

export async function getPreviewsImagesUrlFromProduct(id, product) {

  // Create a reference under which you want to list
  const listRef = ref(storage, `images/${product}/${id}`);

  var images = [];

  // Find all the prefixes and items.
  const list = await listAll(listRef);
  for (const i of list.items) {
    await getDownloadURL(i)
      .then((url) =>
        images.push(url)
      )
      .catch((error) => {
        alert('error al cargar imágenes, recargue la página y revise su conexión');
        images.push(null);
      });
  }
  return images;
};


export async function uploadPictures(id, product, images, grayFilter, previewImages) {
  const storage = getStorage();

  if (images == null)
    return;
  var index = 0, idx = 0;
  const addIdx = () => idx++;


  async function deletePicture(image) {
    const desertRef = ref(storage, image);
    // Delete the file
    await deleteObject(desertRef).then(() => {
      console.log('imagen borrada');
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error);
      return false;
    });
  };

  for (const image of images) {

    const url = previewImages[index];
    if (url.startsWith('http'))
      deletePicture(previewImages[index]);

    if (grayFilter[index] !== '100') {
      const storageRef = ref(storage, `/images/${product}/${id}/${idx}-image`);
      await uploadBytes(storageRef, image).then((snapshot) => {
        addIdx();
      }).catch((error) => {
        // Uh-oh, an error occurred!
        alert('Error al borrar imagen');
        return false;
      });
    }
    index++;
  }
  return true;
}

export async function uploadPicture(path, image) {

  const storage = getStorage();
  const storageRef = ref(storage, path);
  var status = false;
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, image).then((snapshot) => {
    status = true;
  });
  return status;
}

export async function getPreviewImageUrlFromPaper(title) {

  // Create a reference under which you want to list
  const imageRef = ref(storage, `articulo/${title}`);
  var response = null;
  await getDownloadURL(imageRef)
    .then((url) => response = url)
    .catch((error) => { return 'error'; });
  return response;
};

export async function getBannerImages(callback) {
  const bannerFolderRef = ref(storage, 'Banner');

  try {
    const list = await listAll(bannerFolderRef);
    let imageUrls = [];

    for (const item of list.items) {
      const url = await getDownloadURL(item);
      imageUrls.push({ url, name: item.name });
    }

    // Ordenar los URLs alfabéticamente por el nombre del archivo
    imageUrls.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Extraer solo los URLs de las imágenes ordenadas
    imageUrls = imageUrls.map(item => item.url);

    callback(imageUrls);
  } catch (error) {
    console.error('Error al obtener imágenes de la carpeta Banner:', error);
    callback([]);
  }
}