import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import "./Tips.css"; // Importa el archivo CSS de estilos

import { getAllPapers } from "utils/API/ArticulosAPI";
import { getPreviewImageUrlFromPaper } from "utils/API/Pictures";

const title = {
  fontFamily: "Nunito Sans, sans-serif",
  fontSize: '80px',
};

export default function Tips() {
  const [papers, setPapers] = useState([]);

  useEffect(function () {
    async function go() {
      const papers = await getAllPapers();
      for (const paper of papers) {
        paper.url = await getPreviewImageUrlFromPaper(paper.id);
      }

      papers.sort((a, b) => a.order - b.order);
      setPapers(papers);
    }
    go();
  }, []);

  return (
    <div className="tips-container" style={{ backgroundColor: 'white' }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h2 style={title}>TIPS PARA TU VEHÍCULO</h2>
      </div>
      <div style={{ borderBottom: "1px solid #000000", marginBottom: '40px', marginLeft: '10%', marginRight: '10%' }}></div>

      <div className="papers-container">
        {papers.map((paper) => (
          <PreviewPaper key={paper.id} paper={paper} />
        ))}
      </div>
    </div>
  );
}

function PreviewPaper({ paper }) {
  const history = useHistory();

  return (
    <div
      className="preview-paper"
      onClick={() => history.push(`/Paper?id=${paper.id}`)}
    >
      <img src={paper.url} alt="url" className="picture" />
      <div className="text-container">
        <div className="title">{paper.title}</div>
        <div className="description">{paper.description}</div>
        <Button variant="contained" className="view-button">
          Ver completo
        </Button>
      </div>
    </div>
  );
}
