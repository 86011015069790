import { doc, collection, setDoc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "ConfigFirebase";

/* UUID */
import { v4 as uuidv4 } from "uuid";
import { getNumber, incrementAndSaveNumber } from "./NumberOfSales";
import { getAuth } from "firebase/auth";

export async function addNewSell(body) {
  let status = true;

  const auth = getAuth();
  const userEmail = auth?.currentUser?.email;
  if (userEmail) body.userEmail = userEmail;

  try {
    const ticket = await getNumber();
    await incrementAndSaveNumber();
    const sellRef = doc(db, "ventas", uuidv4());
    await setDoc(sellRef, { ...body, ticket: "# " + ticket }, { merge: true });
  } catch (e) {
    status = false;
  }
  return status;
}

export async function getSellAPI(uuid) {
  const docRef = doc(db, "ventas", uuid);
  const docSnap = await getDoc(docRef);

  let data = null;

  if (docSnap.exists()) {
    data = docSnap.data();
  } else {
    console.log("No such document!");
  }
  return data;
}

export async function getAllSellsAPI() {
  const querySnapshot = await getDocs(collection(db, "ventas"));
  return querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
}

export async function updateSell(id, body) {
  let status = true;
  try {
    const sellRef = doc(db, "ventas", id);
    await setDoc(sellRef, body, { merge: true });
  } catch (e) {
    status = false;
  }
  return status;
}

export async function getSellsByUserEmailAPI(userEmail) {
  const q = query(
    collection(db, "ventas"),
    where("userEmail", "==", userEmail)
  );
  const querySnapshot = await getDocs(q);

  let data = [];

  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  if (data.length === 0) {
    console.log("No documents found!");
  }

  return data;
}
