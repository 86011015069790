import React from "react";
import { Container } from "@mui/material";
import ProductCard from "./ProductCard";

const ProductList = ({ products }) => {
  return (
    <Container sx={{ py: { xs: 1, md: 5 } }} maxWidth="lg">
      <ProductCard products={products} />
    </Container>
  );
};

export default ProductList;
