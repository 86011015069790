import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/* Views */
import HomePage from "views/HomePage/HomePage";
import CategorySection from "views/CategorySection/CategorySection";
import Ventas from "views/Administrador/Ventas/Ventas";
import Registro from "views/Registros/Registro";
import Carrito from "views/Carrito/Carrito";
import AdminLogin from "views/AdminLogin/AdminLogin";
import PanelAdministracion from "views/PanelAdministracion/PanelAdministracion";
import EnviosyPagos from "views/EnviosyPagos/EnviosyPagos";
import Nosotros from "views/Nosotros/Nosotros";
import Contacto from "views/Contacto/Contacto";
import Tips from "views/Tips/Tips";
import TipsRegistro from "views/Tips/TipsRegistro";
import TipsOrdenar from "views/Tips/TipsOrdenar";
import BannersOrdenar from "views/Banners/BannersOrdenar";
import TipsEditar from "views/Tips/TipsEditar";

/* Components */
import SearchBar from "components/SearchBar/SearchBar";
import ContactBar from "components/ContactBar/ContactBar";
import Footer from "components/Footer/Footer";
import Publicaciones from "./views/Publicaciones/Publicaciones";
import Producto from "./views/Productos/Producto.js";
import AvisoDePrivacidad from "views/AvisoDePrivacidad/AvisoDePrivacidad";
import PaginaEnServicio from "components/PaginaEnServicio/PaginaEnServicio";
import FilterSearchProducts from "views/FilterSeachProducts/FilterSearchProducts";
import Paper from "views/Paper/Paper";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoginUser from "components/SiginUser/SigininUser";
import Register from "components/SiginUser/Register";
import Profile from "components/SiginUser/Profile";
import TerminosYCondiciones from "views/TerminosYCondiciones/TerminosYCondiciones";
import PoliticasDeCompra from "views/PoliticasDeCompra/PoliticasDeCompra";
import { Box } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E21608",
    },
    secondary: {
      main: "#BC0900",
    },
    back: {
      main: "rgb(0,0,0)",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <SearchBar />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ContactBar />
          </Box>
          <Switch>
            <Route path="/PaginaEnServicio">
              <PaginaEnServicio />
            </Route>
            <Route path="/AvisoDePrivacidad">
              <AvisoDePrivacidad />
            </Route>
            <Route path="/TerminosYCondiciones">
              <TerminosYCondiciones />
            </Route>
            <Route path="/PoliticasDeCompra">
              <PoliticasDeCompra />
            </Route>
            <Route path="/Categoria">
              <CategorySection />
            </Route>
            <Route path="/Registro">
              <Registro />
            </Route>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/Ventas">
              <Ventas />
            </Route>
            <Route exact path="/Publicaciones">
              <Publicaciones />
            </Route>
            <Route exact path="/Producto">
              <Producto />
            </Route>
            <Route exact path="/Carrito">
              <Carrito />
            </Route>
            <Route exact path="/Login">
              <AdminLogin />
            </Route>
            <Route exact path="/LoginUser">
              <LoginUser />
            </Route>
            <Route exact path="/RegisterUser">
              <Register />
            </Route>
            <Route exact path="/Profile">
              <Profile />
            </Route>
            <Route exact path="/PanelAdministracion">
              <PanelAdministracion />
            </Route>
            <Route exact path="/EnviosyPagos">
              <EnviosyPagos />
            </Route>
            <Route exact path="/Nosotros">
              <Nosotros />
            </Route>
            <Route exact path="/Contacto">
              <Contacto />
            </Route>
            <Route exact path="/Tips">
              <Tips />
            </Route>
            <Route exact path="/TipsRegistro">
              <TipsRegistro />
            </Route>
            <Route exact path="/OrdenarTips">
              <TipsOrdenar />
            </Route>
            <Route exact path="/OrdenarBanners">
              <BannersOrdenar />
            </Route>
            <Route exact path="/Busqueda">
              <FilterSearchProducts />
            </Route>
            <Route exact path="/TipsEditar">
              <TipsEditar />
            </Route>
            <Route exact path="/Paper">
              <Paper />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
