import React from "react";

import { Divider, Grid } from "@mui/material";
import facebook from "assets/iconsBlack/fb.png";
import instagram from "assets/iconsBlack/ig.png";
import tiktok from "assets/iconsBlack/tk.png";
import whatsapp from "assets/iconsBlack/whapp.png";

import { Box } from "@material-ui/core";

function Nosotros() {
  var pageStyle = {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "100%",
  };

  var titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: "70px",
    fontWeight: "700",
    marginBottom: "10px",
  };

  var contentStyle = {
    padding: "10px",
  };

  const textSection = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div style={pageStyle}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          xs={10}
          md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={titleStyle}
        >
          <h2 style={titleStyle}>¡CONTÁCTANOS!</h2>
          <Divider
            style={{
              backgroundColor: "black",
              height: "2px",
              bottom: "-5px",
              width: "100%",
              left: 0,
            }}
          />
        </Grid>

        <Grid container xs={10} md={8} align="left" style={contentStyle}>
          <div>
            <p style={textSection}>
              Contáctanos para encontrar los mejores productos para el
              mantenimiento preventivo de tu automóvil, tenemos años de
              experiencia en esta área y podemos ayudarle perfectamente a
              encontrar el kit de mantenimiento o refacción que se acople a sus
              necesidades y posibilidades.
            </p>

            <p style={textSection}>
              Nuestras ventas son totalmente online por lo que no contamos con
              puntos de venta físicos, pero te atenderemos perfectamente en
              cualquiera de nuestros medios digitales y podrás hacer tu compra
              de manera segura en esta página web.
            </p>

            <p style={textSection}>
              Nuestro equipo de trabajo es servicial, amigable y siempre
              dispuesto a responder las dudas que pudieras llegar a tener por lo
              que no dudes en contactarnos, para nosotros es un placer atenderte
              y ayudarte.
            </p>

            <p style={textSection}>
              El objetivo de AFINAZONE es asegurarnos de que puedas encontrar
              los productos que necesitas y se acoplen a tus posibilidades,
              nuestros expertos te podrán apoyar en resolver cualquier duda así
              que contáctanos con confianza en cualquiera de nuestras redes
              sociales o medios de contacto.
            </p>

            <Box
              style={{ display: "flex" }}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={facebook}
                  alt={"logo"}
                  style={{
                    height: "20px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    openInNewTab("https://www.facebook.com/afinazonemx/")
                  }
                />
                <Box
                  component="div"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    openInNewTab("https://www.facebook.com/afinazonemx/")
                  }
                >
                  {" "}
                  Afinazone MX.
                </Box>
              </div>

              <div style={{ display: "flex" }}>
                <a
                  href="https://instagram.com/afinazonemx?utm_medium=copy_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt={"logo"}
                    style={{
                      height: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      openInNewTab("https://instagram.com/afinazonemx?utm_medium=copy_link")
                    }
                  />
                </a>
                <Box
                  component="div"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    openInNewTab("https://instagram.com/afinazonemx?utm_medium=copy_link")
                  }
                >
                  {" "}
                  afinazonemx
                </Box>
              </div>

              <div style={{ display: "flex" }}>
                <a
                  href="https://www.tiktok.com/@afinazonemx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={tiktok}
                    alt={"logo"}
                    style={{
                      height: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      openInNewTab("https://www.tiktok.com/@afinazonemx")
                    }
                  />
                </a>
                <Box
                  component="div"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    openInNewTab("https://www.tiktok.com/@afinazonemx")
                  }
                >
                  {" "}
                  afinazonemx
                </Box>
              </div>

              <div style={{ display: "flex" }}>
                <a
                  href="https://wa.me/524613126256"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={whatsapp}
                    alt={"logo"}
                    style={{
                      height: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      openInNewTab("https://wa.me/524613126256")
                    }
                  />
                </a>
                <Box
                  component="div"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    openInNewTab("https://wa.me/524613126256")
                  }
                >
                  {" "}
                  461-312-6256 
                </Box>
              </div>
            </Box>

            <p style={textSection}>
              Si requieres una respuesta lo más rápida posible te sugerimos
              enviar mensaje por Whatsapp, te responderemos en menos de 5
              minutos en horario laboral.
            </p>

            <p style={textSection}>
              En caso de requerir una atención mucho más personalizada también
              ponemos a tu disposición el siguiente correo electrónico:
            </p>

            <a href="mailto: afinazone@outlook.com">afinazone@outlook.com</a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Nosotros;
