// Pedidos.js
import { useQuery } from "helpers/helpers";
import React, { useEffect, useState } from "react";
import { getSellsByUserEmailAPI } from "utils/API/VentasAPI";

const Pedidos = ({ user, updateSell }) => {
  const [pedidos, setPedidos] = useState([]);
  let query = useQuery();

  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const sells = await getSellsByUserEmailAPI(user.email);
        const sellNumber = query.get("sell");
        const sell = sells.find((sell) => {
          console.log(parseInt(sell.ticket.replace("#", "").trim()), sellNumber);
          return parseInt(sell.ticket.replace("#", "").trim()) === parseInt(sellNumber)});
        if(sell) updateSell(sell);

        setPedidos(
          sells.sort((a, b) => {
            const numA = parseInt(a.ticket.replace("#", "").trim());
            const numB = parseInt(b.ticket.replace("#", "").trim());
            return numA < numB;
          })
        );
      } catch (error) {
        console.error("Error fetching pedidos:", error);
      }
    };

    fetchPedidos();
    // eslint-disable-next-line
  }, [user.email]);

  const tableHeaderRowStyle = {
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
  };

  const tableHeaderCellStyle = {
    padding: "10px",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const cellStyle = {
    padding: "8px",
  };

  const linkStyle = {
    color: "inherit", // Hereda el color del texto padre
    textDecoration: "underline",
    cursor: "pointer",
  };

  const calculateTotal = (products) => {
    const total = products.reduce(
      (total, producto) => total + producto.price,
      0
    );
    const formattedTotal = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return formattedTotal;
  };

  return (
    <div>
      <h2>Pedidos</h2>
      <table style={tableStyle}>
        <thead>
          <tr style={tableHeaderRowStyle}>
            <th style={tableHeaderCellStyle}>Orden</th>
            <th style={tableHeaderCellStyle}>Fecha</th>
            <th style={tableHeaderCellStyle}>Estado de Pago</th>
            <th style={tableHeaderCellStyle}>Estado del Pedido</th>
            <th style={tableHeaderCellStyle}>Total</th>
          </tr>
        </thead>
        <tbody>
          {pedidos.map((pedido, index) => (
            <tr key={index}>
              <td
                style={{ ...cellStyle, ...linkStyle }}
                onClick={() => updateSell(pedido)}
              >
                {pedido.ticket}
              </td>
              <td style={cellStyle}>{pedido.date}</td>
              <td style={cellStyle}>pagado</td>
              <td style={cellStyle}>{pedido.status}</td>
              <td style={cellStyle}>${calculateTotal(pedido.products)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Pedidos;
