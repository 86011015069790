import React, { useEffect, useState } from "react";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Pedidos from "./Pedidos";
import Pedido from "./Pedido";

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("Usuario no autenticado");
  const [sell, setSell] = useState(null);


  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const db = getFirestore();
        const docRef = doc(db, "users", user.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setProfile(docSnap.data());
            setUser(user);
            setError(null);
          } else {
            setError("No se encontraron datos del perfil.");
          }
        } catch (err) {
          setError("Error al obtener los datos del perfil.");
        }
      } else {
        setError("Usuario no autenticado.");
      }
      setLoading(false);
    });
  }, []);

  function updateSell(sell) {
    setSell(sell);
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        minHeight="100vh"
        margin="50px"
      >
        <Typography variant="h4" gutterBottom>
          Mi Cuenta
        </Typography>
        <Typography variant="h6">Nombre: {profile.firstName}</Typography>
        <Typography variant="h6">Apellido: {profile.lastName}</Typography>
        <Typography variant="h6">
          Correo Electrónico: {profile.email}
        </Typography>
        {sell == null && (
          <Pedidos user={user} updateSell={updateSell}></Pedidos>
        )}
        {sell && <Pedido pedido={sell} updateSell={updateSell}></Pedido>}
      </Box>
    </Container>
  );
};

export default Profile;
