import { useState, useEffect } from "react";

/* Material UI */
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

/* Firebase */
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import { isAdmin } from "utils/API/Login";

const container = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
  minHeight: '65vh',
};

const textField = {
  margin: '10px',
};

export default function AdminLogin() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            setUser(user);
            alert('Sesión iniciada');
          })
          .catch((error) => {
            alert('Usuario o contraseña incorrecta');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSignOut() {
    const auth = getAuth();
    signOut(auth).then(() => {
      setUser(null);
      alert('Sesión terminada correctamente');
    }).catch((error) => {
      alert('Ocurrió un error al terminar su sesión');
      // An error happened.
    });
  }

  useEffect(function () {

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        const roleAdmin = await isAdmin(user); 
        setAdmin(roleAdmin);
      } 
    });
  }, []);


  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <div style={container}>
      {user === null &&
        <form onSubmit={handleSubmit} style={container}>

          <TextField
            style={textField}
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            style={textField}
            type="password"
            label="Contraseña"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" style={{ width: '100px' }}> Iniciar Sesión </Button>
        </form>
      }
      {user !== null && admin &&
        <div>
          <div>
            Usuario autenticado con {user.email}.
          </div>
          <Button variant="contained" style={{ width: '200px', margin: '10px' }} onClick={handleSignOut}> Cerrar Sesión </Button>
        </div>
      }
    </div>
  );
}