import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  Delete,
  Edit,
  Save,
  Add,
} from "@mui/icons-material";

import { getLinks, saveLinks } from "utils/API/LinksAPI";

const SortableList = () => {
  const [items, setItems] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");

  useEffect(() => {
    async function fetchData() {
      const data = await getLinks();
      setItems(data.links);
    }
    fetchData();
  }, []);

  // Move item up in the list
  const moveUp = (index) => {
    if (index > 0) {
      const updatedItems = [...items];
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      setItems(updatedItems);
    }
  };

  // Move item down in the list
  const moveDown = (index) => {
    if (index < items.length - 1) {
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      setItems(updatedItems);
    }
  };

  // Add a new item to the list
  const addItem = () => {
    if (newTitle.trim()) {
      setItems([...items, newTitle]);
      setNewTitle("");
    }
  };

  // Delete an item from the list
  const deleteItem = (index) => {
    const shouldDelete = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (shouldDelete) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
    }
  };

  // Edit an item inline
  const editItem = (index) => {
    setEditingIndex(index);
    setEditingTitle(items[index]); // Mantener el valor inicial al editar
  };

  // Save the edited item
  const saveItem = (index) => {
    const updatedItems = [...items];
    updatedItems[index] = editingTitle;
    setItems(updatedItems);
    setEditingIndex(null);
  };

  const saveChanges = async () => {
    const status = await saveLinks(items);
    if(status)
      alert('links guardados correctamente')
    else  alert('no se pudieron actualizar los links');
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "40px auto" }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Ordenar Banners
        </Typography>

        {/* Form to add a new item */}
        <Box sx={{ display: "flex", mb: 2 }}>
          <TextField
            label="Nuevo Link"
            variant="outlined"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addItem}
            startIcon={<Add />}
            style={{
              marginRight: '10px'
            }}
          >
            Agregar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveChanges}
            startIcon={<Save />}
          >
            Guardar Cambios
          </Button>
        </Box>

        {/* List of items */}
        <List>
          {items.map((item, index) => (
            <ListItem key={index} sx={{ alignItems: "center" }}>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                {editingIndex === index ? (
                  <TextField
                    value={editingTitle}
                    onChange={(e) => setEditingTitle(e.target.value)}
                    sx={{ width: "70%" }} // Limitar el ancho del campo de texto
                  />
                ) : (
                  <ListItemText
                    primary={item}
                    sx={{
                      maxWidth: "70%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                )}
              </Box>
              <ListItemSecondaryAction sx={{ right: 0 }}>
                <IconButton
                  edge="end"
                  onClick={() => moveUp(index)}
                  disabled={index === 0}
                >
                  <ArrowUpward />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => moveDown(index)}
                  disabled={index === items.length - 1}
                >
                  <ArrowDownward />
                </IconButton>
                {editingIndex === index ? (
                  <IconButton edge="end" onClick={() => saveItem(index)}>
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton edge="end" onClick={() => editItem(index)}>
                    <Edit />
                  </IconButton>
                )}
                <IconButton edge="end" onClick={() => deleteItem(index)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default SortableList;
