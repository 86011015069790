import { doc, setDoc, collection, getDocs, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "ConfigFirebase";
import { deleteProductAPI } from "./Product";

export async function addNewOilTypeAPI(typeOil) {

  let status = true;
  try {
    const brandRef = doc(db, 'oilTypes', typeOil.name);
    await setDoc(brandRef, typeOil, { merge: true });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function deleteOilTypeAPI(typeOil) {

  let status = true;
  try {
    const brandRef = doc(db, 'oilTypes', typeOil.name);
    await deleteDoc(brandRef);

    //Elimina productos con el modelo anterior
    const arrayProducts = await getDocs(collection(db, 'Ac'));
    arrayProducts.forEach(async (Doc) => {
      let item = Doc.data();
      if (item.typeOfOil === typeOil.name) {
        await deleteProductAPI(Doc.id, 'Ac');
      }
    });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function getOilTypesAPI() {

  const clln = collection(db, "oilTypes");
  const querySnapshot = await getDocs(clln);
  let docsData = [];
  querySnapshot.forEach((doc) => {
    const item = { ...doc.data() };
    docsData.push(item.name);
  });

  return docsData;
}


export async function editOilType(oilType, newName) {
  let status = true;
  try {
    await deleteOilTypeAPI({ name: oilType });
    await addNewOilTypeAPI({ name: newName });

    //Actualiza productos con el modelo anterior
    const categories = ['Ac'];
    for (const idx in categories) {
      let cat = categories[idx]
      const arrayProducts = await getDocs(collection(db, cat));
      arrayProducts.forEach(async (Doc) => {
        let item = Doc.data();
        if (item.typeOfOil === oilType) {
          let productRef = doc(db, cat, Doc.id);
          await updateDoc(productRef, {
            typeOfOil: newName,
          });
        }
      });
    }
  }
  catch (e) {
    status = false;
  }
  return status;
}