import { useState, useEffect } from "react";

/* Sections */
import Form from "./Sections/Form";

/* Helpers */
import { useQuery, PRODUCT } from "helpers/helpers.js";

/* Components */
import Header from "components/Header/Header";
import CategorySelector from "components/CategorySelector/CategorySelector";

/* Router */
import { useHistory } from "react-router-dom";

/* Icons */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/* API */
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isAdmin } from "utils/API/Login";

function Registro() {
  let query = useQuery();
  let history = useHistory();

  const [product, setProduct] = useState(null);

  useEffect(() => {
    const product = query.get("product");
    setProduct(product);

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        const roleAdmin = await isAdmin(user);
        setAdmin(roleAdmin);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);

  const productOptions = [
    { name: "Kit de Afinación para Transmisión", value: "KAT" },
    { name: "Kit de Afinación para Motor", value: "KAM" },
    { name: "Cambio de Aceite", value: "CaAc" },
    { name: "Aceite", value: "Ac" },
    { name: "Filtro", value: "Flt" },
    { name: "Bujía", value: "Bj" },
    { name: "Accesorios", value: "Accs" },
    { name: "Enfriadores", value: "Enf" },
    { name: "Cárters", value: "Cart" },
  ];

  const handleSelectedProduct = (productSelected) => {
    history.push(`/Registro?product=${productSelected}`);
    setProduct(productSelected);
  };

  const returnToCategorySelector = (productSelected) => {
    history.push("/Registro");
    setProduct(null);
  };
  if (user && admin) {
    if (product === null) {
      return (
        <div>
          <Header> ¿Qué categoria tiene tu producto? </Header>
          <CategorySelector
            options={productOptions}
            handleSelected={handleSelectedProduct}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Header> Registro de {PRODUCT[product]} </Header>
          <h2
            style={{
              marginLeft: "15%",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowBackIcon
              style={{
                fontSize: "50px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={returnToCategorySelector}
            />
            Rellena los datos de tu nuevo producto o da click en la flecha para
            regresar al selector de categorías.
          </h2>
          <Form product={product} />
        </div>
      );
    }
  } else return null;
}

export default Registro;
