const title = {
  fontFamily: "Nunito Sans, sans-serif",
};

const text = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "300",
};

const container = {
  padding: "5%",
  textAlign: "start",
};

export default function TerminosYCondiciones() {
  return (
    <div style={container}>
      <h2 style={title}> USO DE LA PLATAFORMA </h2>
      <p style={text}>
        Al utilizar nuestra plataforma de comercio electrónico, aceptas los
        siguientes términos y condiciones (T&C).
      </p>

      <h3 style={title}> Usuarios </h3>
      <p style={text}>
        Al acceder o realizar transacciones en nuestro sitio, aceptas estos T&C.
        Los usuarios deben ser mayores de 18 años o contar con la autorización
        de sus padres o tutores.
      </p>

      <h3 style={title}>Política de Uso</h3>
      <p style={text}>
        Nos reservamos el derecho de cancelar o restringir cuentas en caso de
        uso indebido de la plataforma. Esto incluye actividades como la reventa
        de productos adquiridos en el sitio o cualquier otra actividad comercial
        no autorizada.
      </p>

      <h3 style={title}>Seguridad</h3>
      <p style={text}>
        Implementamos medidas de seguridad para proteger tus datos y
        transacciones. La violación de la seguridad del sitio puede resultar en
        acciones civiles o penales.
      </p>

      <h3 style={title}>Registro de Usuarios</h3>
      <p style={text}>
        Para realizar acciones como compras, devoluciones, etc., los usuarios
        deben registrarse en el sitio. Garantizamos la seguridad y privacidad de
        tus datos personales.
      </p>

      <h3 style={title}>Información de Productos</h3>
      <p style={text}>
        Proporcionamos descripciones detalladas de nuestros productos,
        incluyendo disponibilidad, precios y garantías aplicables. Te
        recomendamos revisar esta información antes de realizar tu compra.
      </p>

      <h3 style={title}>Disponibilidad de Productos</h3>
      <p style={text}>
        Los productos están sujetos a disponibilidad y se ofrecen hasta agotar
        existencias. Te informaremos si un producto no está disponible y
        procederemos al reembolso correspondiente.
      </p>

      <h3 style={title}>Medios de Pago</h3>
      <p style={text}>
        Aceptamos varios métodos de pago, incluyendo tarjetas de crédito, débito
        y pagos en efectivo a través de Paynet.
      </p>

      <h3 style={title}>Cupones y Promociones</h3>
      <p style={text}>
        Ofrecemos cupones y promociones sujetos a condiciones específicas. Nos
        reservamos el derecho de modificar o cancelar estas ofertas en cualquier
        momento.
      </p>

      <h3 style={title}>Facturación</h3>
      <p style={text}>
        Puedes solicitar tu comprobante fiscal dentro de los 30 días posteriores
        a la compra.
      </p>

      <h3 style={title}>Entrega y Mensajería</h3>
      <p style={text}>
        Los pedidos se preparan y entregan dentro de los plazos establecidos.
        Ofrecemos opciones de entrega a domicilio y en tienda.
      </p>

      <h3 style={title}>Devoluciones y Reembolsos</h3>
      <p style={text}>
        Aceptamos devoluciones dentro de los 30 días posteriores a la compra. Se
        aplican ciertas condiciones.
      </p>

      <h3 style={title}>Atención al Cliente</h3>
      <p style={text}>
        Para consultas, sugerencias o reclamos, contáctanos a través de nuestro
        servicio de atención al cliente.
      </p>

      <h3 style={title}>Privacidad</h3>
      <p style={text}>
        Respetamos tu privacidad y protegemos tus datos personales de acuerdo
        con nuestra política de privacidad.
      </p>

      <h3 style={title}>Propiedad Intelectual</h3>
      <p style={text}>
        Los contenidos del sitio están protegidos por derechos de autor y
        propiedad intelectual.
      </p>

      <h3 style={title}>Cumplimiento Legal</h3>
      <p style={text}>
        Nos comprometemos a cumplir con la legislación vigente, incluida la
        prevención de operaciones con recursos de procedencia ilícita.
      </p>

      <h3 style={title}>Modificaciones y Jurisdicción</h3>
      <p style={text}>
        Nos reservamos el derecho de modificar estos T&C en cualquier momento.
        Las disputas se resolverán de acuerdo con la legislación mexicana y la
        jurisdicción de los tribunales de la Ciudad de México.
      </p>
    </div>
  );
}
