// Pedido.js
import React from "react";

const Pedido = ({ pedido, updateSell }) => {

  const { ticket, date, products } = pedido;

  // Función para calcular el subtotal
  const calculateSubtotal = () => {
    return products
      .reduce((subtotal, product) => {
        return subtotal + product.price * product.quantity;
      }, 0)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const calculateShipment = () => {
    const total = products.reduce((subtotal, product) => {
      return subtotal + product.price * product.quantity;
    }, 0);
    return total > 549 ? 0 : 149;
  };

  // Función para calcular el total
  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal().replace(/,/g, ""));
    return (subtotal + calculateShipment()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  // Función para formatear la fecha
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("es-ES");
  };

  const tableHeaderRowStyle = {
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
  };

  const tableHeaderCellStyle = {
    padding: "10px",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const cellStyle = {
    padding: "8px",
  };

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  };

  const backArrowStyle = {
    marginRight: "10px",
    cursor: "pointer",
  };
  return (
    <div>
      <div style={headerStyle}>
        <span style={backArrowStyle} onClick={() => updateSell(null)}>
          ←
        </span>
        <h2>
          Número de pedido: {ticket} - {formatDate(date)}
        </h2>
      </div>
      <p>Estatus: {pedido.status}</p>
      <p>{pedido.name}</p>
      <p>
        {pedido.street}, {pedido.colony}
      </p>
      <p>
        {pedido.city}, {pedido.state}
      </p>
      <p>
        {pedido.country}, {pedido.postalCode}
      </p>

      <table style={tableStyle}>
        <thead>
          <tr style={tableHeaderRowStyle}>
            <th style={tableHeaderCellStyle}>Producto</th>
            <th style={tableHeaderCellStyle}>Precio</th>
            <th style={tableHeaderCellStyle}>Cantidad</th>
            <th style={tableHeaderCellStyle}>Total</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td style={cellStyle}>{product.name}</td>
              <td style={cellStyle}>
                ${product.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </td>
              <td style={cellStyle}>{product.quantity}</td>
              <td style={cellStyle}>
                $
                {(product.price * product.quantity)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <p>Subtotal: ${calculateSubtotal()}</p>
        <p>Envío: ${(calculateShipment()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
        <h3>Total: ${calculateTotal()}</h3>
      </div>
    </div>
  );
};

export default Pedido;
