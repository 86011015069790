import React, { useEffect, useState } from "react";

/* Sections */
/*
import Resumen from './Sections/Resumen';
import FiltradoBusqueda from './Sections/FiltradoBusqueda';
*/
import Vendidos from "./Sections/Vendidos";

/* API */
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isAdmin } from "utils/API/Login";

function Ventas() {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        const roleAdmin = await isAdmin(user);
        setAdmin(roleAdmin);
      }
    });
  }, []);

  if (user && admin) {
    return (
      <div>
        {/*
                    <Resumen />
                    <FiltradoBusqueda />
                */}
        <Vendidos />
      </div>
    );
  } else return null;
}

export default Ventas;
