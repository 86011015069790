import {
  doc,
  collection,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "ConfigFirebase";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { PRODUCT } from "helpers/helpers";

export async function addNewProduct(uuid, body, product) {
  let status = true;
  try {
    const KitRef = doc(db, product, uuid);
    await setDoc(KitRef, body, { merge: true });
  } catch (e) {
    status = false;
  }
  return status;
}

export async function findAndUpdatePreviousBestSeller(number) {
  try {
    // Recorremos cada colección en PRODUCT
    for (const key in PRODUCT) {
      const collectionName = key;
      const querySnapshot = await getDocs(collection(db, collectionName));

      // Iteramos sobre cada documento en la colección
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();

        // Comprobamos si el número está en los datos del documento
        if (data["bestSeller"] === number) {
          const docRef = doc.ref;
          await setDoc(docRef, { 'bestSeller': 0 }, { merge: true });
        }
      });
    }
    return true;
  } catch (error) {
    console.error("Error updating previous best seller:", error);
    return false;
  }
}

export async function getProductAPI(uuid, product) {
  const docRef = doc(db, product, uuid);
  const docSnap = await getDoc(docRef);

  let data = null;

  if (docSnap.exists()) {
    data = docSnap.data();
  } else {
    console.log("No such document!");
  }
  return data;
}

export async function getAllProductsAPI(product) {
  const querySnapshot = await getDocs(collection(db, product));
  return querySnapshot;
}

export async function deleteProductAPI(uuid, product) {
  let status = true;
  const storage = getStorage();

  try {
    await deleteDoc(doc(db, product, uuid));

    // Create a reference to the file to delete
    const listRef = ref(storage, `images/${product}/${uuid}`);

    listAll(listRef).then((res) => {
      res.items.forEach(async function (itemRef) {
        await deleteObject(itemRef)
          .then(() => {})
          .catch((error) => {
            status = false;
          });
      });
    });
  } catch (e) {
    status = false;
  }
  return status;
}
