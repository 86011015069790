import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

export function isLogged() {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      return user;
    }
    else return null;
  });
}

export async function isAdmin(user) {
  if (!user) {
    return false;
  }
  
  const db = getFirestore();
  const adminsRef = collection(db, "admins");
  const q = query(adminsRef, where("email", "==", user.email));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
}