import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "ConfigFirebase";

export async function getLinks() {

  const docRef = doc(db, 'linksBanners', 'agkOCNkLnchHXo4vuMYg');
  const docSnap = await getDoc(docRef);

  let data = null;

  if (docSnap.exists()) {
    data = docSnap.data();
  } else {
    console.log("No such document!");
  }
  return data;
}

export async function saveLinks(links) {
  const docRef = doc(db, 'linksBanners', 'agkOCNkLnchHXo4vuMYg');

  let status = true;
  try {
    await updateDoc(docRef, {
      links: links // Actualiza el campo 'links' con el nuevo arreglo
    });
  } catch (error) {
    console.error("Error actualizando los links: ", error);
    status = false;
  }
  return status;
}