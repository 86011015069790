const title = {
  fontFamily: "Nunito Sans, sans-serif",
};

const text = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "300",
};

const container = {
  padding: "5%",
  textAlign: "start",
};

const boldUnderline = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "700",
  textDecoration: "underline",
};

export default function AvisoDePrivacidad() {
  return (
    <div style={container}>
      <h2 style={title}> AVISO DE PRIVACIDAD INTEGRAL CLIENTES PÁGINA WEB </h2>
      <p style={text}>
        Este aviso está dirigido a todos los usuarios titulares de datos
        personales que se encuentren en posesión de Afinazone, en cumplimiento
        de la Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares, su Reglamento y demás legislación aplicable.
      </p>

      <h3 style={title}>
        {" "}
        1. Identidad y domicilio del responsable del tratamiento de datos
        personales.{" "}
      </h3>
      <p style={text}>
        Afinazone es una sociedad constituida conforme a las leyes de los
        Estados Unidos Mexicanos, con domicilio en Guanajuato.
      </p>
      <p style={text}>
        El presente aviso tiene como objetivo proteger los datos personales de
        los usuarios del sitio web de Afinazone, con el fin de evitar daños,
        pérdida, destrucción, robo, extravío o alteración, así como el
        tratamiento no autorizado de los mismos.
      </p>

      <h3 style={title}>
        {" "}
        1. Identidad y domicilio del responsable del tratamiento de datos
        personales.{" "}
      </h3>
      <p style={text}>
        Afinazone es una sociedad constituida conforme a las leyes de los
        Estados Unidos Mexicanos, con domicilio en Guanajuato.
      </p>

      <h3 style={title}> 2. Finalidades del tratamiento. </h3>
      <p style={text}>
        Los datos personales que recabaremos serán utilizados para las
        siguientes finalidades, necesarias para la prestación de nuestros
        servicios:
      </p>
      <p style={text}>1. Comercialización de nuestros bienes y/o productos.</p>
      <p style={text}>
        2. Procesamiento de la compra de nuestros bienes y/o productos.
      </p>
      <p style={text}>
        3. Procesamiento del pago de nuestros bienes y/o productos.
      </p>
      <p style={text}>
        4. Cumplimiento de las obligaciones derivadas de la comercialización de
        nuestros bienes y/o productos.
      </p>
      <p style={text}>
        6. Devolución y/o cambio de los bienes y/o productos adquiridos.
      </p>
      <p style={text}>
        7. Aplicación de garantía respecto de los bienes y/o productos
        adquiridos.
      </p>
      <p style={text}>8. Emisión de facturas y/o recibos.</p>
      <p style={text}>9. Incorporación en nuestra base de datos de clientes.</p>
      <p style={text}>
        10. Información y/o contacto sobre envío y/o fecha de entrega estimada
        del bien y/o producto adquirido.
      </p>
      <p style={text}>
        13. Realización de la actualización de datos personales.
      </p>
      <p style={text}>
        14. Identificación de gustos, hábitos, preferencias e historial de
        compras para ofrecer bienes y/o productos adecuados.
      </p>
      <p style={text}>
        15. Atención a peticiones, consultas, quejas, solicitudes, reclamaciones
        y sugerencias.
      </p>
      <p style={text}>
        16. Atención a procedimientos judiciales y administrativos en los que
        Afinazone sea parte.
      </p>
      <p style={text}>
        17. Envío de avisos sobre cambios en el presente Aviso de Privacidad.
      </p>
      <p style={text}>
        Además, los datos personales también serán utilizados para finalidades
        no necesarias para los servicios solicitados, tales como:
      </p>
      <p style={text}>
        1. Desarrollo y administración de nuestros bienes y/o productos.
      </p>
      <p style={text}>
        2. Proporcionar publicidad, propaganda, avisos y notificaciones sobre
        nuestros bienes y/o productos, en la medida que lo permita la ley
        aplicable.
      </p>
      <p style={text}>
        3. Evaluación de la calidad del servicio o de los bienes y/o productos
        que comercializamos.
      </p>
      <p style={text}>4. Estudios de mercado.</p>
      <p style={text}>5. Información sobre promociones y publicidad.</p>

      <h2 style={title}>
        {" "}
        3. Procedimiento para manifestar negativa al tratamiento de datos
        personales para finalidades no necesarias.{" "}
      </h2>
      <p style={text}>
        Si no desea que sus datos personales sean tratados para las finalidades
        descritas como no necesarias, puede enviar un correo electrónico en
        cualquier momento a la dirección:{" "}
        <span style={boldUnderline}> avisodeprivacidad@afinazone.com.mx </span>,
        solicitando que sus datos personales no sean utilizados para dichas
        finalidades. Su negativa no afectará la prestación de los servicios y
        productos que solicita o contrata con nosotros.
      </p>

      <h2 style={title}> 4. Datos personales sujetos a tratamiento. </h2>
      <p style={text}>
        Para llevar a cabo las finalidades descritas, se informa que los datos
        personales que serán sometidos a tratamiento corresponden a datos de
        identificación, contacto, patrimoniales y financieros.
      </p>

      <h2 style={title}> 5. Datos personales sensibles. </h2>
      <p style={text}>
        Afinazone no tratará datos personales sensibles, ya que no son
        necesarios para las finalidades descritas.
      </p>

      <h2 style={title}> 6. Transferencias de datos personales. </h2>
      <p style={text}>
        Los datos personales serán tratados por Afinazone y terceros nacionales
        o extranjeros que, por su relación contractual, comercial o de negocios
        con nuestra empresa, necesiten tratar dichos datos para las finalidades
        establecidas en este Aviso de Privacidad.
      </p>

      <h2 style={title}>
        {" "}
        7. Mecanismo para oposición a transferencias de datos personales para
        finalidades no necesarias.{" "}
      </h2>
      <p style={text}>
        Si no desea que sus datos personales sean transferidos a terceros, puede
        enviar un correo electrónico en cualquier momento a la dirección:
        <span style={boldUnderline}> avisodeprivacidad@afinazone.com.mx </span>.
        Si no manifiesta ninguna oposición en un plazo de cinco (5) días hábiles
        siguientes a la recepción de este aviso, se entenderá que autoriza
        expresamente a Afinazone a transferir sus datos personales.
      </p>
      <p style={text}>
        También informamos que en caso de fusiones, divisiones o cambios en la
        estructura corporativa de Afinazone, sus datos personales serán
        transmitidos a las empresas correspondientes.
      </p>

      <h2 style={title}> 8. Procedimiento para ejercer los derechos ARCO. </h2>
      <p style={text}>
        Usted tiene derecho a conocer los datos personales que hemos recabado de
        usted, así como las finalidades y condiciones de su uso (Derecho de
        Acceso). Además, puede solicitar la corrección de su información en caso
        de ser necesario (Derecho de Rectificación), la eliminación de sus datos
        de nuestros registros cuando no se utilicen de acuerdo a la normativa
        (Derecho de Cancelación) y oponerse al uso de sus datos para ciertos
        fines (Derecho de Oposición). Estos derechos se conocen como derechos
        ARCO. Para ejercerlos, los titulares de los datos pueden enviar su
        solicitud a través del correo electrónico
        <span style={boldUnderline}> avisodeprivacidad@afinazone.com.mx</span>.
        Las solicitudes deben cumplir con los siguientes requisitos:
      </p>
      <p style={text}>
        - Incluir nombre, domicilio y correo electrónico para recibir la
        respuesta.
      </p>
      <p style={text}>
        - Adjuntar una identificación oficial que acredite la identidad del
        titular.
      </p>
      <p style={text}>
        - Adjuntar una identificación oficial que acredite la identidad del
        titular.
      </p>
      <p style={text}>
        - Adjuntar cualquier elemento que facilite la localización de los datos
        personales.
      </p>
      <p style={text}>
        La respuesta se enviará por correo electrónico en un plazo máximo de
        veinte días hábiles desde la recepción de la solicitud. En caso de que
        la respuesta sea positiva, se realizará la acción correspondiente en un
        plazo máximo de quince días hábiles a partir de que el titular reciba la
        respuesta. Si no se recibe respuesta en el plazo indicado, se entenderá
        que la solicitud ha sido rechazada.
      </p>

      <h2 style={title}>
        {" "}
        9. Revocación del consentimiento al tratamiento de datos personales.{" "}
      </h2>
      <p style={text}>
        Usted tiene derecho a revocar el consentimiento otorgado para el
        tratamiento de sus datos personales. Sin embargo, es importante tener en
        cuenta que en algunos casos no será posible atender la solicitud de
        manera inmediata debido a obligaciones legales. Además, la revocación
        del consentimiento puede implicar la interrupción de ciertos servicios o
        la terminación de la relación con nosotros. Para revocar su
        consentimiento, puede enviar un correo electrónico a
        <span style={boldUnderline}> avisodeprivacidad@afinazone.com.mx</span>,
        incluyendo la siguiente información:
      </p>
      <p style={text}>
        - Nombre, domicilio y correo electrónico para recibir la respuesta.
      </p>
      <p style={text}>- Identificación oficial que acredite su identidad.</p>
      <p style={text}>
        - Cualquier elemento que facilite la localización de sus datos
        personales.
      </p>
      <p style={text}>
        La respuesta se enviará por correo electrónico en un plazo máximo de
        veinte días hábiles desde la recepción de la solicitud de revocación.
      </p>

      <h2 style={title}>
        {" "}
        10. Comunicación de los cambios al Aviso de Privacidad.{" "}
      </h2>
      <p style={text}>
        Cualquier modificación al presente Aviso de Privacidad será informada a
        través de nuestro sitio web www.afinazone.com.mx. Nos reservamos el
        derecho de actualizar periódicamente este aviso, notificándolo de manera
        oportuna a través del medio mencionado anteriormente.
      </p>
      <p style={text}>Fecha de última actualización: 27 de julio de 2024.</p>
    </div>
  );
}
