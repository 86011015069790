import { useEffect, useState } from "react";

/* Material UI */
import { Grid } from "@material-ui/core";

/* Pictures */
import afinazone from "assets/iconos/afinazone.png";

/* API */
import { getOilTypesAPI } from "utils/API/OilTypes";

import Box from "@mui/material/Box";

export default function OilType(props) {
  const [availableOils, setAvailableOils] = useState(null);
  const [notAvailableOils, setNotAvailableOils] = useState(null);

  useEffect(() => {
    const go = async () => {
      const oilTypes = await getOilTypesAPI();

      let checkOils = {};
      oilTypes.forEach((oil) => {
        checkOils[oil] = false;
      });
      for (var product of props.products) {
        if (checkOils[product.typeOfOil] !== undefined)
          checkOils[product.typeOfOil] = true;
      }

      let avOils = [];
      let notAvOils = [];

      for (var check of Object.keys(checkOils)) {
        checkOils[check] ? avOils.push(check) : notAvOils.push(check);
      }

      setAvailableOils(avOils);
      setNotAvailableOils(notAvOils);
    };

    if (props.products !== null) {
      go();
    }
  }, [props.products]);

  return (
    <Grid container spacing={3}>
      {availableOils !== null &&
        availableOils.map((item, idx) => {
          return (
            <Grid key={idx} item xs={6} lg={3}>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "10%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  componen="span"
                  sx={{
                    fontSize: { xs: "12px", md: "20px" },
                  }}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  {item}
                </Box>
                <img
                  src={afinazone}
                  alt="tipo de aceite"
                  style={{ height: "80%", width: "80%", cursor: "pointer" }}
                  onClick={() => {
                    localStorage.setItem("typeOfOil", item);
                    props.setOptionSection("brandOil");
                    props.setTypeOfOil(item);
                  }}
                  className="zoom-on-hover"
                />
              </div>
            </Grid>
          );
        })}
      <Box
        component={Grid}
        sx={{
          fontSize: { xs: "20px", md: "40px" },
        }}
        key={"not brands"}
        item
        sm={12}
        style={{
          textAlign: "left",
          textDecoration: "underline",
          fontFamily: "Nunito Sans, sans-serif",
        }}
      >
        No disponibles
      </Box>
      {notAvailableOils !== null &&
        notAvailableOils.map((item, idx) => {
          return (
            <Grid key={idx} item xs={6} lg={3}>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  componen="span"
                  sx={{
                    fontSize: { xs: "12px", md: "20px" },
                  }}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  {item}
                </Box>
                <img
                  src={afinazone}
                  alt="marca"
                  style={{ height: "80%", width: "80%", cursor: "pointer" }}
                  className="zoom-on-hover"
                />
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
}
