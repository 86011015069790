import { Typography, Menu, MenuItem } from "@mui/material";
import question from "assets/Signo.png";
import facebook from "assets/fb.png";
import instagram from "assets/ig.png";
import tiktok from "assets/tiktok.png";
import { openInNewTab, PRODUCT } from "helpers/helpers";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const ContactSection = () => {
  let history = useHistory();
  const openInTab = (url) => {
    history.push(url);
    if (url.startsWith("Categoria")) window.location.reload();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [seeMore, setSeeMore] = useState(true);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setSeeMore(true);
  };

  return (
    <div
      style={{
        background: "#BC0900",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: "0 20px",
        color: "white",
        height: "50px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInTab("/")}
        >
          Inicio
        </Typography>
        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => openInTab("/")}
          >
            Categorías
          </Typography>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMouseLeave}
            MenuListProps={{
              style: {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            {Object.keys(PRODUCT).map((item, index) => {
              return (
                <>
                  {index < 4 && (
                    <MenuItem
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => openInTab(`Categoria?section=${item}`)}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "grey")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "black")
                      }
                    >
                      {" "}
                      {PRODUCT[item]}{" "}
                    </MenuItem>
                  )}
                  {index === 4 && seeMore === true &&
                    <MenuItem
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => setSeeMore(false)}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "grey")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "black")
                      }
                    >
                      {"Ver Más"}
                    </MenuItem>
                  }
                  {index >= 4 && seeMore === false && 
                    <MenuItem
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => openInTab(`Categoria?section=${item}`)}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "grey")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "black")
                      }
                    >
                      {" "}
                      {PRODUCT[item]}{" "}
                    </MenuItem>
                  }
                </>
              );
            })}
          </Menu>
        </div>

        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />

        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInTab("/EnviosyPagos")}
        >
          {" "}
          Envíos y Pagos{" "}
        </Typography>
        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />

        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInTab("/Tips")}
        >
          {" "}
          Tips para tu vehículo{" "}
        </Typography>
        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />

        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInTab("/Contacto")}
        >
          {" "}
          Contacto{" "}
        </Typography>
        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />

        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInTab("/Nosotros")}
        >
          {" "}
          Conócenos{" "}
        </Typography>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={question}
          alt={"logo"}
          style={{ height: "20px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => openInNewTab("https://wa.me/524613126256")}
        />
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => openInNewTab("https://wa.me/524613126256")}
        >
          Atención al Cliente
        </Typography>

        <div
          style={{
            background: "white",
            width: "1px",
            height: "20px",
            margin: "10px",
          }}
        />
        <img
          src={facebook}
          alt={"logo"}
          style={{ height: "20px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => openInNewTab("https://www.facebook.com/afinazonemx/")}
        />
        <img
          src={instagram}
          alt={"logo"}
          style={{ height: "20px", marginRight: "10px", cursor: "pointer" }}
          onClick={() =>
            openInNewTab("https://instagram.com/afinazonemx?utm_medium=copy_link")
          }
        />
        <img
          src={tiktok}
          alt={"logo"}
          style={{ height: "20px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => openInNewTab("https://www.tiktok.com/@afinazonemx")}
        />
      </div>
    </div>
  );
};

export default ContactSection;
