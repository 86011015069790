import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "ConfigFirebase";

export async function addNewBrandAPI(brand) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brand.name);
    await setDoc(brandRef, brand, { merge: true });
  } catch (e) {
    status = false;
  }
  return status;
}

export async function deleteBrandAPI(brand) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brand.name);
    await deleteDoc(brandRef);
  } catch (e) {
    status = false;
  }
  return status;
}

export async function deleteVariantAPI(brand, variant) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brand);
    const docSnap = await getDoc(brandRef);
    const data = docSnap.data();
    for (let curr of data.variants) {
      for (let key in curr) {
        if (key === variant) {
          delete curr[key];
        }
      }
    }
    await updateDoc(brandRef, data);
  } catch (e) {
    status = false;
  }
  return status;
}

export async function getBrandsAPI() {
  const querySnapshot = await getDocs(collection(db, "brands"));
  return querySnapshot;
}

export async function editBrandModel(brandName, modelName, newName) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brandName);

    // Crea nuevo modelo
    await updateDoc(brandRef, {
      models: arrayUnion(newName),
    });
    // Borra modelo anterior
    await updateDoc(brandRef, {
      models: arrayRemove(modelName),
    });

    //Actualiza productos con el modelo anterior
    const categories = ["Accs", "Bj", "CaAc", "Flt", "KAM", "KAT"];
    for (const idx in categories) {
      let cat = categories[idx];
      const arrayProducts = await getDocs(collection(db, cat));
      arrayProducts.forEach(async (Doc) => {
        let item = Doc.data();
        if (item.model === modelName) {
          let productRef = doc(db, cat, Doc.id);
          await updateDoc(productRef, {
            model: newName,
          });
        }
      });
    }
    await editVariantsWithModelChangeName(brandName, modelName, newName);
  } catch (e) {
    status = false;
  }
  return status;
}

export async function editVariantModel(brandName, variantName, newName) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brandName);
    const docSnap = await getDoc(brandRef);
    const data = docSnap.data();
    for (let curr of data.variants) {
      for (let key in curr) {
        if (key === variantName) {
          const model = curr[key];
          delete curr[key];
          curr[newName] = model;
        }
      }
    }
    await updateDoc(brandRef, data);
  } catch (e) {
    status = false;
  }
  return status;
}

export async function editVariantsWithModelChangeName(
  brandName,
  modelName,
  newName
) {
  let status = true;
  try {
    const brandRef = doc(db, "brands", brandName);
    const docSnap = await getDoc(brandRef);
    const data = docSnap.data();
    for (let curr of data.variants) {
      for (let key in curr) {
        if (curr[key] === modelName) {
          curr[key] = newName;
        }
      }
    }
    await updateDoc(brandRef, data);
  } catch (e) {
    status = false;
  }
  return status;
}
