import Shipping from "assets/Shipping.png";
import { Box } from "@mui/material";

export default function ShoppingInfo() {
  return (
    <Box
      component="div"
      sx={{
        marginRight: { sx: "0px", md: "5%" },
        marginLeft: { sx: "0px", md: "5%" },
        paddingBottom: "20px",
      }}
    >
      <Box
        component="img"
        src={Shipping}
        alt="Shipping Info"
        sx={{ width: { xs: "100%", md: "90%" } }} // Estilo de la imagen
      />
    </Box>
  );
}
