import React from "react";

/* Sections */
import LabelSelector from "./Sections/LabelSelector";
import OptionSelector from "./Sections/OptionSelector";

/* Helpers */
import { PRODUCT, useQuery } from "helpers/helpers";

import Box from "@mui/material/Box";

const TYPE_REQUIRE_MODEL_AND_BRAND = {
  KAT: true,
  Accs: false,
  Bj: true,
  KAM: true,
  CaAc: true,
  Ac: true,
  Flt: true,
  Enf: true,
  Cart: true,
};
export default function KitDeAfinacion() {
  let query = useQuery();

  React.useEffect(function () {
    const section = query.get("section");
    setSection(section);

    if (section !== "Ac") {
      if (!TYPE_REQUIRE_MODEL_AND_BRAND[section]) {
        setOptionSection("product");
        return;
      }

      var car = localStorage.getItem("car");
      if (car === null || car === undefined) {
        setOptionSection("car");
        return;
      }
      var model = localStorage.getItem("model");
      if (model === null || model === undefined) {
        setOptionSection("model");
        return;
      }
    } else if (section === "Ac") {
      var typeOfOil = localStorage.getItem("typeOfOil");
      if (typeOfOil === null || typeOfOil === undefined) {
        setOptionSection("typeOfOil");
        return;
      }
      var brandOil = localStorage.getItem("brandOil");
      if (brandOil === null || brandOil === undefined) {
        setOptionSection("brandOil");
        return;
      }
    }

    setOptionSection("product");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [optionSection, setOptionSection] = React.useState(null);
  const [section, setSection] = React.useState(null);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        component="div"
        sx={{
          fontSize: { xs: "30px", md: "50px" },
          height: { xs: "15vh", md: "40vh" },
        }}
        style={{
          fontColor: "black",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "70%",
            fontFamily: "Nunito Sans, sans-serif",
          }}
        >
          {section && PRODUCT[section]}
        </div>
      </Box>
      <div style={{
        backgroundColor: 'white',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <LabelSelector
          optionSection={optionSection}
          setOptionSection={setOptionSection}
          hideArrow={!TYPE_REQUIRE_MODEL_AND_BRAND[section]}
        />
        <OptionSelector
          optionSection={optionSection}
          setOptionSection={setOptionSection}
        />
      </div>
    </div>
  );
}
