import { useEffect, useState } from "react";

/* Material UI */
import { Grid, Divider, Collapse, Box } from "@mui/material";

/* API */
import { getBrandsAPI } from "utils/API/Brands";

export default function ModelsMobile(props) {
  const [availableModels, setAvailableModels] = useState(null);
  const [notAvailableModels, setNotAvailableModels] = useState(null);
  const [allVariants, setAllVariants] = useState(null);
  const [expandedModels, setExpandedModels] = useState({});

  useEffect(() => {
    const go = async () => {
      var brands = await getBrandsAPI();
      var models;
      var variants = {};
      brands.forEach((doc) => {
        const brand = { ...doc.data() };
        if (brand.name === props.brand) {
          models = brand.models;

          if (brand.variants !== undefined) {
            for (var variant of brand.variants) {
              for (var current in variant) {
                let model = variant[current];
                if (variants[model] === undefined) {
                  variants[model] = [];
                }
                variants[model].push({ variant: current, active: false });
              }
            }
          }
        }
      });

      let checkModels = {};
      models.forEach((model) => {
        checkModels[model] = false;
      });
      const products = props.products.filter(
        (item) => item.brand === props.brand
      );
      for (var product of products) {
        if (checkModels[product.model] !== undefined) {
          if (product.variant !== undefined) {
            let idx = variants[product.model].findIndex(
              // eslint-disable-next-line
              (item) => item.variant === product.variant
            );
            if (variants[product.model] && idx > -1) {
              variants[product.model][idx].active = true;
              checkModels[product.model] = true;
            }
          }
        }
      }

      let avModels = [];
      let notAvModels = [];

      for (var check of Object.keys(checkModels)) {
        checkModels[check] ? avModels.push(check) : notAvModels.push(check);
      }

      avModels.sort();
      notAvModels.sort();
      setAvailableModels(avModels);
      setNotAvailableModels(notAvModels);
      setAllVariants(variants);
    };
    if (props.products !== null && props.brand !== null) {
      go();
    }
  }, [props.products, props.brand]);

  const toggleExpand = (model) => {
    setExpandedModels((prev) => ({ ...prev, [model]: !prev[model] }));
  };

  return (
    <Grid container spacing={1}>
      {availableModels !== null &&
        availableModels.map((item, idx) => (
          <Grid key={`model-${idx}`} item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => toggleExpand(item)}
            >
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "15px",
                  flexGrow: 1, // Para que el texto ocupe el espacio disponible
                }}
              >
                {item}
              </div>
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderBottom: expandedModels[item]
                    ? "8px solid black"
                    : "none", // Triángulo hacia abajo si está expandido
                  borderTop: expandedModels[item] ? "none" : "8px solid black", // Triángulo hacia arriba si está colapsado
                  transition: "border 0.3s",
                }}
              />
            </div>
            <Divider
              style={{
                marginTop: "5px",
                backgroundColor: "#f2f2f2",
                height: "1px",
              }}
            />
            <Collapse in={expandedModels[item] || false}>
              <Box>
                {allVariants !== null &&
                  allVariants[item] !== undefined &&
                  allVariants[item].map((variant, index) => (
                    <Grid
                      item
                      sm={4}
                      style={{ color: "#bc0900" }}
                      key={`variant-${index}`}
                    >
                      {variant.active ? (
                        <div
                          style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            fontWeight: "900",
                            color: "#0000EE",
                            cursor: "pointer",
                            marginTop: "5px",
                            textAlign: "right",
                          }}
                          onClick={() => {
                            localStorage.setItem("model", item);
                            props.setOptionSection("product");
                            props.setModel(item);
                          }}
                        >
                          {variant.variant}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "900",
                            marginTop: "5px",
                            textAlign: "right",
                          }}
                        >
                          {variant.variant}
                        </div>
                      )}
                    </Grid>
                  ))}
              </Box>
            </Collapse>
          </Grid>
        ))}

      <Grid item sm={12}>
        <div
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
            fontSize: "18px",
            textAlign: "center",
            margin: "10px",
          }}
        >
          No disponibles
        </div>
      </Grid>

      {notAvailableModels !== null &&
        notAvailableModels.map((item, idx) => (
          <Grid key={`nv-model-${idx}`} item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => toggleExpand(item)}
            >
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "15px",
                  flexGrow: 1, // Para que el texto ocupe el espacio disponible
                }}
              >
                {item}
              </div>
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderBottom: expandedModels[item]
                    ? "8px solid black"
                    : "none", // Triángulo hacia abajo si está expandido
                  borderTop: expandedModels[item] ? "none" : "8px solid black", // Triángulo hacia arriba si está colapsado
                  transition: "border 0.3s",
                }}
              />
            </div>
            <Divider
              style={{
                marginTop: "5px",
                backgroundColor: "#f2f2f2",
                height: "1px",
              }}
            />
            <Collapse in={expandedModels[item] || false}>
              <Box>
                {allVariants !== null &&
                  allVariants[item] !== undefined &&
                  allVariants[item].map((variant, index) => (
                    <Grid
                      item
                      sm={4}
                      style={{ color: "#bc0900" }}
                      key={`variant-nv-${index}`}
                    >
                      {variant.active ? (
                        <div
                          style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            fontWeight: "900",
                            color: "#0000EE",
                            cursor: "pointer",
                            textAlign: "right",
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            localStorage.setItem("model", item);
                            props.setOptionSection("product");
                            props.setModel(item);
                          }}
                        >
                          {variant.variant}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "900",
                            textAlign: "right",
                            marginTop: "5px",
                          }}
                        >
                          {variant.variant}
                        </div>
                      )}
                    </Grid>
                  ))}
              </Box>
            </Collapse>
          </Grid>
        ))}
    </Grid>
  );
}
