import React, { useState, useEffect } from 'react';
import './Banner.css';
import { getBannerImages } from 'utils/API/Pictures';
import { getLinks } from 'utils/API/LinksAPI'; // Asegúrate de importar la función para obtener los links

const Banner = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [links, setLinks] = useState([]); // Estado para almacenar los links
  const [currentIndex, setCurrentIndex] = useState(0);

  // Obtener imágenes y links al montar el componente
  useEffect(() => {
    async function fetchData() {
      // Obtener URLs de las imágenes
      getBannerImages((urls) => {
        setImageUrls(urls);
      });

      // Obtener los links
      const data = await getLinks();
      if (data && data.links) {
        setLinks(data.links);
      }
    }

    fetchData();
  }, []); // Solo se ejecuta una vez al montar el componente

  // Cambiar automáticamente las imágenes
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 5000); // Cambia la imagen cada 5 segundos

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]); // Reinicia el intervalo cada vez que currentIndex cambia

  const goToNext = () => {
    const isLastSlide = currentIndex === imageUrls.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleImageClick = () => {
    if (links[currentIndex]) {
      window.open(links[currentIndex], '_blank'); // Abre el link en una nueva pestaña
    }
  };

  return (
    <div className="banner">
      <div className="banner__container">
        {imageUrls.map((url, index) => (
          <div
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            key={index}
            onClick={handleImageClick} // Redirigir al link al hacer clic en la imagen
          >
            <img src={url} alt={`Slide ${index}`} className="banner__image" />
          </div>
        ))}
      </div>
      <div className="banner__dots">
        {imageUrls.map((_, index) => (
          <span
            key={index + 'banner'}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Banner;
