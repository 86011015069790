import React, { useState, useEffect } from "react";

/* Routing */
import { Link } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import imageBroken from "assets/img/image.svg";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Grid, Box } from "@material-ui/core";
import { getAllSellsAPI, updateSell } from "utils/API/VentasAPI";

import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import CurrencyFormat from "react-currency-format";

const itemStyle = {
  marginBottom: "30px",
  padding: "30px",
  backgroundColor: "#d7d7d7",
  border: "1px solid #797979",
  borderRadius: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
};

const infoContainer = {
  display: "flex",
};

const rightContainer = {
};

const imgStyle = {
  height: "80%",
  width: "80%",
};

const field = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  marginBottom: "10px",
};

const iconStyle = {
  cursor: "pointer",
  alignSelf: "center",
};

function Sell({ item }) {
  const [showProducts, setShowProducts] = useState(true);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState(null);
  const [notes, setNotes] = useState(null);
  const [ship, setShip] = useState(null);
  const [orderShip, setOrderShip] = useState(null);

  useEffect(() => {
    setNotes(item.notes);
    setShip(item.ship);
    setOrderShip(item.orderShip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const comission = 0.0395;
  const base = 4;

  const getTotalWithComission = () => {
    return total - total * comission - base;
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const updateStatus = async (e) => {
    const response = await updateSell(item.id, { status: status });
    alert(response ? "actualizado con éxito" : "algo salió mal");
  };

  const updateNotes = async () => {
    const response = await updateSell(item.id, { notes });
    alert(response ? "actualizado con éxito" : "algo salió mal");
  };
  const updateShip = async () => {
    const response = await updateSell(item.id, { ship });
    alert(response ? "actualizado con éxito" : "algo salió mal");
  };
  const updateOrderShip = async () => {
    const response = await updateSell(item.id, { orderShip });
    alert(response ? "actualizado con éxito" : "algo salió mal");
  };

  useEffect(() => {
    const price = item.products.reduce(function (acc, curr) {
      return acc + curr.price;
    }, 0);
    setTotal(price < 549 ? price + 149 : price);
    setStatus(item.status);
  }, [item.products, item.status]);

  const opciones = [
    "Pago confirmado",
    "En preparación",
    "Por generar guía",
    "Lista para enviar",
    "En camino",
    "Entregado",
    "Cancelada",
    "En devolución",
  ];

  return (
    <div style={itemStyle} id={item.ticket.replace("#", "").trim()}>
      <div style={infoContainer}>
        <Box
          component={Grid}
          container
          spacing="1"
          sx={{
            fontSize: { xs: "12px", md: "15px" },
            width: "75%",
            marginRight: "20px",
          }}
        >
          <Grid item xs={5} sm={3} style={field}>
            <b> Fecha </b>
            <div>{item.date}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Nombre </b>
            <div>{item.name}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Código Postal </b>
            <div>{item.postalCode}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Referencias de Domicilio </b>
            <div>{item.referenceAddress}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> No pedido </b>
            <div>
              #{" "}
              {parseInt(item.ticket.replace("#", "").trim())
                .toString()
                .padStart(7, "0")}
            </div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Email </b>
            <div>{item.email}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Calle </b>
            <div>{item.street}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Método de Pago </b>
            <div> Ejemplo momentáneo </div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Total </b>
            <div>
              ${" "}
              <CurrencyFormat
                value={total}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Teléfono </b>
            <div>{item.phone}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Colonia </b>
            <div>{item.colony}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Paquetería </b>
            <div style={{ backgroundColor: "white" }}>
              <TextField
                autoWidth
                value={ship}
                onChange={(e) => setShip(e.target.value)}
              />
            </div>
            <Button onClick={() => updateShip()}> Actualizar </Button>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Total después de comisión</b>
            <div>
              ${" "}
              <CurrencyFormat
                value={getTotalWithComission()}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> País </b>
            <div>{item.country}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Ciudad </b>
            <div>{item.city}</div>
          </Grid>

          <Grid item xs={5} sm={3} style={field}>
            <b> Número de Seguimiento </b>
            <div style={{ backgroundColor: "white" }}>
              <TextField
                autoWidth
                value={orderShip}
                onChange={(e) => setOrderShip(e.target.value)}
              />
            </div>
            <Button onClick={() => updateOrderShip()}> Actualizar </Button>
          </Grid>

          <Grid item xs={6} />
          <Grid item xs={5} sm={3} style={field}>
            <b> Estado </b>
            <div>{item.state}</div>
          </Grid>
        </Box>

        <div style={rightContainer}>
          <div style={field}>
            <b> Notas </b>
            <div style={{ backgroundColor: "white" }}>
              <TextField
                autoWidth
                multiline
                rows={4}
                maxRows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <Button onClick={() => updateNotes()}> Actualizar </Button>
          </div>

          <div style={field}>
            <b> Estado del pedido</b>
            <div style={{ backgroundColor: "white" }}>
              <Select
                autoWidth
                value={status}
                defaultValue={status}
                onChange={(e) => handleChangeStatus(e)}
              >
                {opciones.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </div>
            <Button onClick={() => updateStatus()}> Actualizar </Button>
          </div>
        </div>
      </div>

      {!showProducts && (
        <VisibilityOffIcon
          style={iconStyle}
          onClick={() => setShowProducts(true)}
        />
      )}
      {showProducts && (
        <>
          <VisibilityIcon
            style={iconStyle}
            onClick={() => setShowProducts(false)}
          />
          <ListOfProducts products={item.products} />
        </>
      )}
    </div>
  );
}

function ListOfProducts({ products }) {
  const [url, setUrl] = useState([]);

  useEffect(() => {
    async function go() {
      const urls = await Promise.all(
        products.map(async (current) => {
          const previewUrl = await getPreviewImageUrlFromProduct(
            current.uuid,
            current.type
          );
          return previewUrl;
        })
      );
      setUrl(urls);
    }
    go();
  }, [products]);

  return (
    <Box
      component={Grid}
      container
      sx={{ fontSize: { xs: "12px", md: "15px" } }}
    >
      <Grid item xs={12} style={field}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            fontWeight: "900",
            fontSize: "inherit",
          }}
        >
          Productos pedidos
        </div>
      </Grid>

      {products.map((product, index) => {
        return (
          <>
            <Grid component="div" item xs={6} sm={3}>
              <Link
                to={`/Producto?product=${product.type}&id=${product.uuid}`}
                target="_blank"
              >
                <img
                  src={url[index] ?? imageBroken}
                  alt="car"
                  style={imgStyle}
                />
              </Link>
            </Grid>
            <Grid component="div" item xs={6} sm={3}>
              <p> {product.name} </p>
              <p> {product.description} </p>
              <p> {product.quantity} unidades </p>${" "}
              <CurrencyFormat
                value={product.price}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </Grid>
          </>
        );
      })}
    </Box>
  );
}

function Vendidos() {
  const [sells, setSells] = useState([]);

  useEffect(() => {
    async function go() {
      const arraySells = await getAllSellsAPI();
      arraySells.sort((a, b) => b.realDate - a.realDate);
      setSells(arraySells);
    }
    go();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
        marginLeft: "10vw",
        marginRight: "10vw",
        marginBottom: "20px",
      }}
    >
      {sells.map((item) => {
        return <Sell item={item} />;
      })}
    </div>
  );
}

export default Vendidos;
